<template>
  <div>
    <BNavbar style="background-color: #AC8D64; border-bottom: 1px solid #AC8D64; border-top: 1px solid #AC8D64"
      class="justify-content-center py-0" :container="false">
      <BNavbarNav>
        <BNavItem style="font-size: 1.2rem;" link-class="text-dark fw-normal fst-normal"
          :to="localePath({ name: 'index' })">
          {{ $t("HOME") }}
        </BNavItem>
      </BNavbarNav>
      <BNavbarBrand class="mx-2 px-2 py-2 bg-dark fw-bold" style="color: #AC8D64; font-size: 1.5rem">{{ $t("APP_NAME")
        }}
      </BNavbarBrand>
      <BNavbarNav>
        <BNavItem style="font-size: 1.2rem;" link-class="text-dark fw-normal fst-normal"
          :to="localePath({ name: 'menu' })">
          {{ $t("MENU") }}
        </BNavItem>
      </BNavbarNav>
    </BNavbar>
    <slot />
  </div>
</template>

<style lang="scss">
@import "node_modules/bootstrap/scss/functions";
@import "~/assets/scss/variables";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/variables-dark";

.coloured-underline {
  text-decoration: underline;
  text-decoration-color: $success;
}
</style>
